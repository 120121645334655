import React, { useState } from 'react';
import { Section } from 'react-scroll-section';
// import Carousel from 'react-elastic-carousel';
import { Box, Typography, Button, InputLabel, OutlinedInput } from '@mui/material';
import { Carousel as ResponsiveCarousel } from 'react-responsive-carousel';
import { useWindowResize } from '@cyboticx/hooks';
import { Image, Icon, Testimonial } from 'lib/components';
import { PageLayout } from 'lib/components/Layout';
import { ImageSource } from 'types';
import { SPP_LOGIN_URL, PARTNERS, SPIRE_LINKS } from 'lib/constants';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import toast from 'react-hot-toast';
import { relative } from 'path';
import Card from '@mui/material/Card';

import CardMedia from '@mui/material/CardMedia';
import kuchoriyalogo from '../../public/kuchoriya.jpg';

interface LogoItemProps {
	href?: string;
	src: ImageSource;
	alt?: string;
	className: string;
	imageClass: string;
}
interface SPPFeatureItemProps {
	text: string;
	className?: string;
}

interface ResponsiveCarouselImageProps {
	src: ImageSource;
	className?: string;
}

const SPPFeatureItem = (props: SPPFeatureItemProps) => (
	<Box className={`flex items-center space-x-2 ${props.className ?? ''}`}>
		<Icon name="IcCheck" className="text-secondary-500" />
		<Typography variant="body1" className="font-semibold text-primary-500 font-title">
			{props.text}
		</Typography>
	</Box>
);

const LogoItem = (props: LogoItemProps) => (
	<>
		{props.href !== undefined && (
			<Button
				className={`flex items-center justify-center bg-no-repeat bg-cover rounded-3xl ${props.className}`}
				href={props.href}
				target="_blank"
			>
				<Box>
					<Image src={props.src} alt={props.alt} className={props.imageClass} />
				</Box>
			</Button>
		)}
		{props.href === undefined && (
			<Box className={`flex items-center justify-center bg-no-repeat bg-cover rounded-3xl ${props.className}`}>
				<Image src={props.src} alt={props.alt} className={props.imageClass} />
			</Box>
		)}
	</>
);

const ResponsiveCarouselImage = (props: ResponsiveCarouselImageProps) => (
	<Box className={`flex items-start justify-center ${props.className ?? ''}`}>
		<Image src={props.src} className="h-24" />
	</Box>
);

const TESTIMONIALS_LIST = Array<number>(25).fill(0);

const LandingScreen = () => {
	const { width } = useWindowResize();
	const [email, setEmail] = React.useState('');
	const [name, setName] = React.useState('');
	const [message, setMessage] = React.useState('');
	const [activeTestimonialIndex, setActiveTestimonialIndex] = React.useState(0);
	const [activePartnerIndex, setActivePartnerIndex] = React.useState(5);
	const [formSubmitted, setFormSubmitted] = React.useState(false);
	const [loading, setLoading] = useState(false);
	const itemCenterSlidePercentage = React.useMemo(() => {
		if (width < 385) return 94;
		if (width >= 385 && width < 451) return 90;
		if (width > 450 && width < 531) return 80;
		if (width >= 530 && width < 640) return 70;
		if (width >= 640 && width < 671) return 60;
		if (width >= 670 && width < 721) return 55;
		if (width >= 720 && width < 769) return 50;
		if (width >= 769 && width < 865) return 45;
		if (width >= 865 && width < 1101) return 40;
		if (width >= 1101 && width < 1525) return 35;
		return 30;
	}, [width]);

	const partnersItemCenterSlidePercentage = React.useMemo(() => {
		if (width < 385) return 50;
		if (width >= 385 && width < 451) return 48;
		if (width > 450 && width < 531) return 45;
		if (width >= 530 && width < 640) return 45;
		if (width >= 640 && width < 671) return 40;
		if (width >= 670 && width < 721) return 38;
		if (width >= 720 && width < 769) return 35;
		if (width >= 769 && width < 865) return 30;
		if (width >= 865 && width < 1101) return 25;
		if (width >= 1101 && width < 1525) return 20;
		return 15;
	}, [width]);

	// const partnersCarouselBreakpoints = [
	// 	{ width: 1, itemsToShow: 2, itemsToScroll: 2 },
	// 	{ width: 550, itemsToShow: 4, itemsToScroll: 4 },
	// 	{ width: 850, itemsToShow: 5, itemsToScroll: 5 },
	// ];

	const handleTestimonySlideChange = React.useCallback(
		(index: number) => {
			if (index === activeTestimonialIndex) return;
			setActiveTestimonialIndex(index);
		},
		[activeTestimonialIndex]
	);
	const handlePartnerSlideChange = React.useCallback(
		(index: number) => {
			if (index === activePartnerIndex) return;
			setActivePartnerIndex(index);
		},
		[activePartnerIndex]
	);

	const scrollToContactSection = React.useCallback(() => {
		const section = document.querySelector(`#contact`);
		section?.scrollIntoView({ behavior: 'smooth', block: 'start' });
	}, []);
	const sendMessage = async () => {
		if (!name || !email || !message) {
			toast.error('Please fill in all the fields.', { position: 'bottom-left' });
			setFormSubmitted(true); // Update formSubmitted state to indicate form submission attempt
			return;
		}
		setLoading(true);
		const myHeaders = new Headers();

		myHeaders.append('Content-Type', 'application/json');

		const raw = JSON.stringify({
			name: name,
			email: email,
			message: message,
		});

		const requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: raw,
		};

		try {
			const baseUrl =
				process.env.REACT_APP_API_URL || 'https://sps-api.csi-international.ca' || 'http://localhost:8080';

			const response = await fetch(`${baseUrl}/api/v1/contactus`, requestOptions);
			if (response.ok) {
				setName('');
				setEmail('');
				setMessage('');
				setFormSubmitted(false);
				setLoading(false);
				toast.success('Message sent successfully!', { position: 'bottom-left' });
			} else {
				setLoading(false);
				toast.error('Failed to send message. Please try again.', { position: 'bottom-left' });
			}
		} catch (error) {
			setLoading(false);
			toast.error('An error occurred. Please try again later.', { position: 'bottom-left' });
		}
	};

	return (
		<PageLayout title="" className="pt-20 hide-scrollbar" showSpireLink>
			{/* Hero Section */}
			<Section id="home" className="w-full bg-primary-500">
				<Box className="w-11/12 mx-auto max-w-8xl pt-14">
					<Box className="flex items-center justify-center">
						<Image src="icLogoSchedulePro" className="" />
					</Box>
					<Box className="flex items-center justify-center w-full max-w-3xl mx-auto mt-8">
						<Typography
							variant="h3"
							className="text-xl font-bold text-center text-white xs:text-2xl sm:text-3xl font-title"
						>
							The ultimate scheduling solution <br /> for your company
						</Typography>
					</Box>
					<Box className="flex justify-center mt-8">
						<Button
							href={SPP_LOGIN_URL}
							target="_blank"
							color="inherit"
							className="h-14 w-60 text-secondary-500"
						>
							Login to Schedule Pro
						</Button>
					</Box>
					<Box className="w-11/12 max-w-6xl mx-auto mt-2 lg:mt-10">
						<Image
							src="plDashboardMobile"
							className="w-full -mb-14 xs:-mb-16 lg:hidden object-fit sm:-mb-20 md:-mb-24"
						/>
						<Image src="plDashboard" className="hidden w-full object-fit lg:block" />
					</Box>
				</Box>
			</Section>
			{/* Hero Section - End*/}
			{/* Business Info Section */}
			{/* Mobile */}
			<Box className="w-full mx-auto mt-10 lg:hidden">
				<ResponsiveCarousel
					showArrows={false}
					showStatus={false}
					showThumbs={false}
					centerMode
					selectedItem={1}
					centerSlidePercentage={itemCenterSlidePercentage}
					className="w-full"
					renderItem={(item, options) => (
						<Box className={`${options?.isSelected ? '' : 'opacity-40'}`}>{item}</Box>
					)}
					renderIndicator={(onClick, isSelected, index) => (
						<Icon
							key={index}
							name={isSelected ? 'IcBulletFilled' : 'IcBullet'}
							onClick={(e) => onClick(e)}
							className={`w-3 h-3 mx-1`}
						/>
					)}
				>
					<Box className="w-full px-2">
						<LogoItem
							href={SPIRE_LINKS.website}
							src="icLogoSpireTitle"
							className="w-full h-80 xs:h-96 bg-logo-primary"
							imageClass="w-20 h-20"
						/>
					</Box>
					<Box className="w-full px-2">
						<LogoItem
							src="icLogoSage"
							className="w-full h-80 xs:h-96 bg-logo-light"
							imageClass="w-20 h-20"
						/>
					</Box>
					<Box className="w-full px-2">
						<LogoItem
							src="icLogoCrystal"
							className="w-full h-80 xs:h-96 bg-logo-primary"
							imageClass="w-32 h-32"
						/>
					</Box>
				</ResponsiveCarousel>
			</Box>
			<Box className="w-11/12 max-w-sm mx-auto mt-4 sm:max-w-2xl lg:hidden">
				<Typography
					variant="h4"
					className="text-xl font-semibold text-center xs:text-2xl sm:text-3xl md:text-4xl font-title text-primary-500"
				>
					Spire Systems, Sage and CSI. <br /> What a great combination!
				</Typography>
				<Typography variant="body1" className="mt-4 text-center sm:text-lg md:text-xl">
					{
						"At CSI International we offer installation, training, report writing and technical support for two of Canada's leading ERP Systems; Spire and BusinessVision. Our software support organization works throughout the golden Horseshoe of Southern Ontario."
					}
				</Typography>
				<Box className="flex justify-center w-full mx-auto mt-6">
					<Button className="w-full sm:w-60 h-14" title="Contact us" onClick={scrollToContactSection}>
						Contact us
					</Button>
				</Box>
			</Box>
			{/* Desktop */}
			<Box className="items-center hidden w-11/12 mx-auto xl:space-x-10 2xl:space-x-16 lg:max-w-8xl xl:max-w-8xl lg:block xl:flex mt-28">
				<Box className="flex items-center w-full space-x-4 xl:w-8/12">
					<LogoItem
						href={SPIRE_LINKS.website}
						src="icLogoSpireTitle"
						className="w-full h-72 xl:h-72 2xl:h-80 bg-logo-primary"
						imageClass="w-36"
					/>
					<LogoItem
						src="icLogoSage"
						className="w-full h-72 xl:h-72 2xl:h-80 bg-logo-light"
						imageClass="w-32"
					/>
					<LogoItem
						src="icLogoCrystal"
						className="w-full h-72 xl:h-72 2xl:h-80 bg-logo-primary"
						imageClass="w-32"
					/>
				</Box>
				<Box className="w-full max-w-3xl mx-auto mt-10 xl:mt-0 xl:w-4/12 xl:max-w-none xl:mx-0">
					<Typography
						variant="h4"
						className="text-4xl font-semibold text-center xl:text-2xl 2xl:text-3xl font-title text-primary-500 xl:text-left"
					>
						Spire Systems, Sage and CSI. <br /> What a great combination!
					</Typography>
					<Typography
						variant="body1"
						className="mt-2 text-lg text-center xl:text-sm 2xl:mt-4 2xl:text-sm 2xl:leading-loose xl:text-left"
					>
						{
							"At CSI International we offer installation, training, report writing and technical support for two of Canada's leading ERP Systems; Spire and BusinessVision. Our software support organization works throughout the golden Horseshoe of Southern Ontario."
						}
					</Typography>
					<Box className="flex justify-center w-full xl:justify-start">
						<Button
							className="w-full mt-4 xl:mt-3 2xl:mt-4 sm:w-60 h-14"
							title="Contact us"
							onClick={scrollToContactSection}
						>
							Contact us
						</Button>
					</Box>
				</Box>
			</Box>
			{/* Business Info Section - End */}
			{/* Schedule Pro Features Section */}
			<Box className="w-11/12 mx-auto mt-10 bg-no-repeat bg-cover sm:max-w-lg md:max-w-xl lg:max-w-8xl lg:h-100 bg-features-mobile lg:bg-features rounded-3xl lg:flex lg:items-center sm:mt-14 lg:mt-20">
				<Box className="w-full pt-6 pl-6 xs:pl-10 sm:pl-12 sm:py-12 lg:w-1/2 lg:h-full">
					<Typography
						variant="h4"
						className="text-2xl font-semibold sm:text-3xl text-secondary-500 font-title"
					>
						Features of Schedule Pro
					</Typography>
					<SPPFeatureItem text="Work Orders" className="mt-8 lg:mt-10" />
					<SPPFeatureItem text="Schedule Board" className="mt-4" />
					<SPPFeatureItem text="Orders Tracking" className="mt-4" />
					<SPPFeatureItem text="Easy to Use" className="mt-4" />
					<Button
						color="secondary"
						href={SPP_LOGIN_URL}
						target="_blank"
						className="mt-10 w-60 h-14"
						title="Login to Schedule Pro"
					>
						Login to Schedule Pro
					</Button>
				</Box>
				<Box className="flex justify-center w-full pb-10 mt-10 lg:w-1/2 lg:mt-0 lg:h-full lg:items-center sm:pb-12 lg:pb-0">
					<Image src="ilCalendar" className="w-9/12" />
				</Box>
			</Box>
			{/* Schedule Pro Features Section - End */}
			{/* Partners Section */}
			<Box className="container" style={{ backgroundColor: 'white', maxWidth:'1000px', padding:'40px 20px', margin:'60px auto', borderRadius:'20px', boxShadow: 'rgb(116 115 128 / 15%) 1px 1px 68px 4px', }}>
				<Typography variant="body1" className="text-center text-secondary-500 mb-4 ">
					<h4 className='MuiTypography-h4 text-2xl font-semibold xl:text-3xl text-primary-500 mt-0'>TECHNOLOGY PARTNERS</h4>
				</Typography>
				<div style={{ marginLeft: '56px', marginRight: '60px' }}>
					<Card sx={{ display: 'flex' }} style={{borderRadius:'0px', boxShadow: 'none',}}>
						<CardMedia
							style={{borderRadius:'15px',}}
							component="img"
							sx={{ width: 251, objectFit: 'contain' }}
							image={kuchoriyalogo}
							alt="Kuchoriya Software Logo"
						/>
						{/* <style>
						.mui-1oqv2c0-MuiPaper-root-MuiCard-root{
							borderRadius:'0px',
						}
						</style> */}
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								marginLeft: '30px',
								marginRight: '10px',
							}}
						>
							<Box sx={{ textAlign: 'left' }}>
								<a href="https://www.kuchoriyatechsoft.com/"  style={{textDecorationLine:'none', fontStyle:'bold',}}
									target="_blank"
									rel="noopener noreferrer">
								<h1 style={{margin:'0',}}>Kuchoriya Software</h1>
								</a>
								
								<p >
									Kuchoriya TechSoft: Leading technology partner for CSI International, providing
									innovative software solutions and services.
								</p>
								<a
									href="https://www.kuchoriyatechsoft.com/request-quote"
									target="_blank"
									rel="noopener noreferrer"
								>
									<Button variant="contained" sx={{ marginBottom: '10px' }}>
										Contact Now
									</Button>
								</a>
							</Box>
						</Box>
					</Card>
				</div>

				{/* <Box className="hidden w-full mt-8 sm:block">
					<Carousel
						breakPoints={partnersCarouselBreakpoints}
						isRTL={false}
						showArrows={false}
						className="w-full"
						itemPadding={[0, 5]}
						renderPagination={({ pages, activePage, onClick }) => (
							<Box className="relative z-20 flex items-center justify-center w-full mt-6 space-x-2">
								{pages.map((page, index) => {
									const isActive = activePage === page;

									return (
										<Icon
											key={index}
											name={isActive ? 'IcBulletFilled' : 'IcBullet'}
											onClick={() => onClick(String(page))}
											className={`w-3 h-3`}
										/>
									);
								})}
							</Box>
						)}
					>
						<Image src="icLogoElectron" className="" />
						<Image src="icLogoCbx" className="" />
						<Image src="icLogoJs" className="" />
						<Image src="icLogoSpire" className="" />
						<Image src="icLogoK8S" className="" />
						<Image src="icLogoPython" className="" />
					</Carousel>
				</Box> */}
			</Box>
			{/* Partners Section - End */}
			{/* Testimonials Section */}
			{false && (
				<Box className="w-full mt-6 lg:mt-10">
					<Typography variant="body1" className="text-center text-secondary-500">
						TESTIMONIALS
					</Typography>
					<Box className="w-11/12 mx-auto">
						<Typography
							variant="h4"
							className="mt-2 text-4xl font-bold text-center text-primary-500 font-title"
						>
							Few words from our clients
						</Typography>
					</Box>
					<Box className="w-11/12 mx-auto mt-10 sm:mt-16 sm:max-w-lg md:max-w-xl lg:hidden">
						<Image src="plGroupMobile" className="w-full" />
					</Box>
					<Box
						className="w-full mt-10 lg:relative lg:flex lg:items-center lg:mt-20"
						sx={{
							height: { xss: 'auto', lg: '35rem', xl: '40rem' },
						}}
					>
						<Box className="absolute z-10 hidden w-6/12 h-full lg:block">
							<Image src="plGroup" className="h-full" />
						</Box>
						<Box className="w-full lg:w-11/12 xl:w-9/12 lg:ml-auto lg:z-20 lg:relative lg:mt-6">
							<ResponsiveCarousel
								showArrows={false}
								showStatus={false}
								showThumbs={false}
								showIndicators={false}
								centerMode
								selectedItem={activeTestimonialIndex}
								centerSlidePercentage={itemCenterSlidePercentage}
								className="w-full"
								renderItem={(item, options) => (
									<Box
										sx={{ filter: options?.isSelected ? undefined : 'blur(2px)' }}
										className="transition duration-300 ease-in-out select-none rounded-3xl"
									>
										{item}
									</Box>
								)}
								onClickItem={handleTestimonySlideChange}
							>
								{TESTIMONIALS_LIST.map((_, index) => (
									<Box key={index} className="w-full px-2">
										<Testimonial
											name="Jonathan McKee"
											description="Manager, Company Co."
											review="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout."
											rating={4}
											className="w-full max-w-sm xs:max-w-lg sm:max-w-sm"
										/>
									</Box>
								))}
							</ResponsiveCarousel>
							<Box className="flex items-center justify-center w-11/12 mx-auto mt-8 space-x-10 sm:max-w-xs lg:mr-1 xl:mr-20">
								<Icon
									name="IcLongArrowLeft"
									className="w-10 cursor-pointer"
									onClick={() => setActiveTestimonialIndex((prev) => prev - 1)}
								/>
								<Typography variant="body1" className="text-xl font-medium font-title">
									<span className="font-medium text-secondary-500 font-title">
										{activeTestimonialIndex + 1}
									</span>{' '}
									/ {TESTIMONIALS_LIST.length}
								</Typography>
								<Icon
									name="IcLongArrowRight"
									className="w-10 cursor-pointer"
									onClick={() => setActiveTestimonialIndex((prev) => prev + 1)}
								/>
							</Box>
						</Box>
					</Box>
				</Box>
			)}
			{/* Testimonials Section - End */}
			{/* Contact Section */}
			<Section
				id="contact"
				className="w-11/12 mx-auto mt-10 sm:max-w-lg md:max-w-xl lg:max-w-8xl lg:mt-16 lg:flex lg:items-end lg:space-x-10 lg:flex-row-reverse lg:space-x-reverse"
			>
				<Box className="w-full lg:w-7/12">
					<Image src="plMapMobile" className="w-full lg:hidden" />
					<Image src="plMap" className="hidden w-full lg:block" />
				</Box>
				<Box className="w-full mt-8 lg:w-5/12 lg:mt-0">
					<Typography variant="h4" className="text-2xl font-semibold xl:text-3xl text-primary-500">
						Contact Us
					</Typography>
					<Box className="w-full mt-4 2xl:mt-6">
						<InputLabel htmlFor="name" className="ml-5 font-semibold text-primary-500">
							Name
						</InputLabel>
						<OutlinedInput
							fullWidth
							id="name"
							type="text"
							placeholder="Type your name.."
							className="h-12 mt-2 font-medium text-primary-500 rounded-4xl bg-lightShade-100"
							value={name}
							onChange={(e) => setName(e.target.value)}
						/>
						{formSubmitted && !name && <div className="text-red-500 ml-5">Please fill in your name.</div>}
					</Box>
					<Box className="w-full mt-4 2xl:mt-6">
						<InputLabel htmlFor="email" className="ml-5 font-semibold text-primary-500">
							Email
						</InputLabel>
						<OutlinedInput
							fullWidth
							id="email"
							type="email"
							placeholder="Type your email address.."
							className="h-12 mt-2 font-medium text-primary-500 rounded-4xl bg-lightShade-100"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
						{formSubmitted && !email && <div className="text-red-500 ml-5">Please fill in your email.</div>}
					</Box>
					<Box className="w-full mt-4 2xl:mt-6">
						<InputLabel htmlFor="message" className="ml-5 font-semibold text-primary-500">
							Message
						</InputLabel>
						<OutlinedInput
							fullWidth
							id="message"
							type="text"
							multiline
							rows={4}
							placeholder="Type your message.."
							className="mt-2 font-medium rounded-4xl bg-lightShade-100 text-primary-500"
							value={message}
							onChange={(e) => setMessage(e.target.value)}
						/>
						{formSubmitted && !message && (
							<div className="text-red-500 ml-5">Please fill in your message.</div>
						)}
					</Box>
					{/* <Button
						variant="contained"
						onClick={sendMessage}
						className="w-full mt-6 2xl:mt-8 h-14 rounded-3xl font-title"
					>
						Send Message
					</Button> */}

					{loading ? (
						<Button variant="contained" className="w-full mt-6 2xl:mt-8 h-14 rounded-3xl font-title">
							<div className="flex items-center justify-center mt-6">
								<div
									className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary-200 "
									style={{
										color: 'white',
										backgroundColor: 'black',
										fontSize: '20px',
										fontWeight: 'bold',
										position: 'relative',
										bottom: '10px',
									}}
								>
									.
								</div>
							</div>
						</Button>
					) : (
						<Button
							variant="contained"
							onClick={sendMessage}
							className="w-full mt-6 2xl:mt-8 h-14 rounded-3xl font-title"
						>
							Send Message
						</Button>
					)}
				</Box>
				{/* <ToastContainer /> */}
				{/* <Toaster/> */}
			</Section>
			{/* Contact Section - End */}
		</PageLayout>
	);
};

export default LandingScreen;
