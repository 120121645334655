import React from 'react';
import { Container, Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const PrivacyPolicy = () => {
	return (
		<Container maxWidth="md" sx={{ py: 4 }}>
			<Box textAlign="center" sx={{ mb: 4 }}>
				<Typography variant="h4" component="h1" gutterBottom color="blue">
					Privacy Policy
				</Typography>
			</Box>

			<Box sx={{ mb: 3 }}>
				<Typography variant="body1" gutterBottom>
					Welcome to Schedule Pro! This Privacy Policy explains how we collect, use, and protect your
					information when you use our enterprise resource planning (ERP) application. By using Schedule Pro,
					you agree to the terms of this Privacy Policy.
				</Typography>
			</Box>

			<Accordion>
				<AccordionSummary expandIcon={<ExpandMoreIcon />}>
					<Typography variant="h6">Information We Collect</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Typography variant="body1">
						We collect information that you provide when you use Schedule Pro, such as:
					</Typography>
					<ul>
						<li>User account details (name, email, role, etc.).</li>
						<li>Business data including tasks, projects, schedules, and financial records.</li>
						<li>Integration details with third-party services (e.g., payroll, CRM, etc.).</li>
						<li>Device and usage information for performance and analytics.</li>
					</ul>
				</AccordionDetails>
			</Accordion>

			<Accordion>
				<AccordionSummary expandIcon={<ExpandMoreIcon />}>
					<Typography variant="h6">How We Use Your Information</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Typography variant="body1">Your information is used to:</Typography>
					<ul>
						<li>Provide and enhance ERP functionalities like task management, reporting, and analytics.</li>
						<li>Facilitate integrations with third-party tools for seamless operations.</li>
						<li>Ensure data security and maintain system reliability.</li>
						<li>Send critical updates or notifications about system changes or features.</li>
					</ul>
				</AccordionDetails>
			</Accordion>

			<Accordion>
				<AccordionSummary expandIcon={<ExpandMoreIcon />}>
					<Typography variant="h6">Data Security</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Typography variant="body1">
						Schedule Pro employs industry-standard security measures to protect your data. These include
						encryption, secure access protocols, and regular audits. However, no system is 100% secure, and
						we encourage you to use strong passwords and monitor access to your account.
					</Typography>
				</AccordionDetails>
			</Accordion>

			<Accordion>
				<AccordionSummary expandIcon={<ExpandMoreIcon />}>
					<Typography variant="h6">Your Rights</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Typography variant="body1">As a user of Schedule Pro, you have the right to:</Typography>
					<ul>
						<li>Access, update, or delete your personal and business information.</li>
						<li>Export critical business data you have stored in the system.</li>
						<li>Control and limit access to sensitive information within your organization.</li>
						<li>Opt out of non-essential system communications.</li>
					</ul>
				</AccordionDetails>
			</Accordion>

			<Accordion>
				<AccordionSummary expandIcon={<ExpandMoreIcon />}>
					<Typography variant="h6">Compliance and Legal Requirements</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Typography variant="body1">
						Schedule Pro complies with relevant data protection laws, including GDPR and CCPA. We ensure
						transparency in data handling and provide tools to manage your compliance obligations
						effectively. Any legal requests for data will be handled per applicable laws.
					</Typography>
				</AccordionDetails>
			</Accordion>

			<Box textAlign="center" sx={{ mt: 4 }}>
				<Typography variant="caption" display="block" color="blue">
					&copy; {new Date().getFullYear()} Schedule Pro. All Rights Reserved.
				</Typography>
			</Box>
		</Container>
	);
};

export default PrivacyPolicy;
