import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import Public from './public';
import Privacy from '../screens/PrivacyPolicy/index';
import Authenticated from './authenticated';
import LandingScreen from 'screens/LandingScreen';
import SpireAboutScreen from 'screens/SpireAboutScreen';
import { useAuthenticationState } from 'store/selectors';

const Routes = () => {
	const { isAuthenticated } = useAuthenticationState();

	return (
		<>
			<Switch>
				<Public exact path={['/', '/logout']} component={LandingScreen} />
				<Public exact path="/spire" component={SpireAboutScreen} />
				<Public exact path={['/privacy', '/privacy']} component={Privacy} />

				{!isAuthenticated && (
					<Public path="*">
						<Redirect to="/" />
					</Public>
				)}
				{!isAuthenticated && (
					<Authenticated path="/privacy">
						<Redirect to="/privacy" />
					</Authenticated>
				)}

				{isAuthenticated && (
					<Authenticated path="*">
						<Redirect to={'/dashboard'} />
					</Authenticated>
				)}
			</Switch>
		</>
	);
};

export default Routes;
